<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-list-group v-if="items && items.length > 0" flush>
    <draggable
      v-model="items"
      @start="drag = true"
      @end="drag = false"
      @change="handlerSort(items)"
    >
      <b-list-group-item v-for="(item, index) in items" :key="index">
        <div class="d-flex justify-content-between align-items-center">
          <b-link
            :class="{ active: item.slug === category_name }"
            :to="
              `/product_category/edit/${item.id}?name=${item.name}&slug=${item.slug}`
            "
          >
            + {{ item.name }} ( {{ item.id_number }} )
          </b-link>
          <div>
            <!-- <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 8L12 2L6 8" stroke="#6e6b7b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6 16L12 22L18 16" stroke="#6e6b7b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg> -->

            <feather-icon
              icon="MoveIcon"
              class="cursor-pointer ml-1"
            />

            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer ml-1"
              @click="handlerDeleteCategory(item.id)"
            />
          </div>
        </div>
        <b-list-group>
          <draggable
            v-model="item.children"
            @start="drag = true"
            @end="drag = false"
            @change="handlerSort(item.children)"
          >
            <b-list-group-item
              v-for="(i, idx) in item.children"
              :key="idx"
              class="border-0 pb-0 pr-0"
            >
              <div class="d-flex justify-content-between align-items-center">
                <b-link
                  class="w-80"
                  :class="{ active: i.slug === category_name }"
                  :to="
                    `/product_category/edit/${i.id}?parent_slug=${item.slug}&slug=${i.slug}`
                  "
                >
                  + {{ i.name }} ( {{ i.id_number }} )
                </b-link>
                <div>
                  <!-- <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 8L12 2L6 8" stroke="#6e6b7b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6 16L12 22L18 16" stroke="#6e6b7b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> -->

                  <feather-icon
                    icon="MoveIcon"
                    class="cursor-pointer ml-1"
                  />

                  <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer ml-1"
                    @click="handlerDeleteCategory(i.id)"
                  />
                </div>
              </div>
              <b-list-group>
                <draggable
                  v-model="i.children"
                  @start="drag = true"
                  @end="drag = false"
                  @change="handlerSort(i.children)"
                >
                  <b-list-group-item
                    v-for="(y, idy) in i.children"
                    :key="idy"
                    class="border-0 pb-0 pr-0"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <b-link
                        class="w-70"
                        :class="{ active: y.slug === category_name }"
                        :to="
                          `/product_category/edit/${y.id}?parent_slug=${i.slug}&name=${y.name}&slug=${y.slug}`
                        "
                      >
                        {{ y.name }} ( {{ y.id_number }} )
                      </b-link>
                      <div>
                        <!-- <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 8L12 2L6 8" stroke="#6e6b7b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M6 16L12 22L18 16" stroke="#6e6b7b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> -->

                        <feather-icon
                          icon="MoveIcon"
                          class="cursor-pointer ml-1"
                        />

                        <feather-icon
                          icon="TrashIcon"
                          class="cursor-pointer ml-1"
                          @click="handlerDeleteCategory(y.id)"
                        />
                      </div>
                    </div>
                  </b-list-group-item>
                </draggable>
              </b-list-group>
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </b-list-group-item>
    </draggable>
  </b-list-group>
</template>

<script>
/* eslint-disable object-shorthand */
import { BListGroup, BListGroupItem, BLink } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import draggable from 'vuedraggable'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BLink,
    draggable,
  },
  mixins: [general],
  data() {
    return {
      items: [],
      category_name: null,
    }
  },
  watch: {
    '$route.params.id'() {
      this.loadList()
      if (this.$route.query.slug !== undefined) {
        this.category_name = this.$route.query.slug
      }
    },
  },
  mounted() {
    this.loadList()
    if (this.$route.query.slug !== undefined) {
      this.category_name = this.$route.query.slug
    }
  },
  methods: {
    handlerChangeCategory(item) {
      // this.$router.push(`product_category/edit/${item.id}?slug=${item.slug}`)
      window.location.href = `product_category/edit/${item.id}?slug=${item.slug}`
    },
    handlerSort(data) {
      const newPosition = []
      data.map((x, index) => {
        const item = {
          id: x.id,
          position: index + 1,
        }
        return newPosition.push(item)
      })
      this.changePosition(newPosition)
      this.$emit('handlerSort')
    },
    async changePosition(list) {
      if (list && list.length > 0) {
        const params = {
          type: 2,
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          ids: list,
        }
        try {
          const res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/category/change_position`,
            params,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Congratulation !',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Sort Category Product success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String(this.showError(res.data.error, ',')),
                },
              })
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },
    async loadList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.items = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDeleteCategory(id) {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        this.$swal({
          title: 'Are you sure?',
          text: 'Sản phẩm hiện tài có thuộc tính còn số lượng trong kho, ban có muốn xóa hay ko !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/category/${id}?site_id=${siteId}`,
            )
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your Product has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loadList()
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item a {
  color: #6e6b7b;
}
.list-group-item:hover {
  cursor: pointer;
}
.list-group-item a.active {
  color: var(--primary);
}
.sortable-ghost .list-group-item,
.sortable-ghost {
  background: var(--primary);
  color: #ffffff;
}
.list-group-item .list-group-item {
  background: transparent;
}
.w-80{
  width: 69%;
}
.w-70{
  width: 65%;
}
</style>
